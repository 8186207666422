    function ajaxcall(page){

    localStorage.removeItem('event_location','event_miles','event_category','page','pagination'); //clear from local storage
        var event_location = $('#event-location').attr('data-location');
        var event_miles = $('#event-miles').attr('data-value');
        var event_category = $('#event-category').attr('data-value');
        var pagination = $("#past ul#pagination-past").html();



        eventSearchByLocation(event_category, event_location, event_miles,page);

        var paginate = page;

        //store the tab in local storage when redirect back it goes to the same tab
        localStorage.setItem('event_location', event_location);
        localStorage.setItem('event_miles', event_miles);
        localStorage.setItem('event_category', event_category);
        localStorage.setItem('page', paginate);
        localStorage.setItem('pagination', pagination);

    }

    function category(that) {

        var event_category_name = $(that).text();
        var event_category = $(that).attr('data-value');
        $('#event-category').val(event_category_name);
        $('#event-category').attr('data-value', event_category);
        var event_location = $('#event-location').attr('data-location');
        var event_miles = $('#event-miles').attr('data-value');
        $('#categoryDropdown').removeClass('show');
        if(event_category!=0){
        $('.search-fa').removeClass('fa-search')
        $('.search-fa').addClass('fa-times');
        }
        eventSearchByLocation(event_category, event_location, event_miles,0);

    }

    function set_location(that) {

        var event_location = $(that).val();
        event_location = event_location.trim();
        //var event_location = $(that).attr('data-location').trim();
        if(event_location!=''){
        $('#event-location').attr('data-location',event_location);
        $('#event-location').text(event_location.substring(0, 22)+'...');
        $(that).val('');
        $('.dropdown').children('span').removeClass('show');
        var event_miles = $('#event-miles').attr('data-value');
        var event_category = $('#event-category').attr('data-value');
        eventSearchByLocation(event_category, event_location, event_miles,0);
        }
    }

    function set_miles(that) {

        var event_miles = $(that).attr('data-value');
        $('#event-miles').attr('data-value', event_miles);
        if (event_miles != 0) {
            $('#event-miles').text(event_miles + ' Miles');
        } else {
            $('#event-miles').text('Any Distance');
        }
        var event_location = $('#event-location').attr('data-location');
        var event_category = $('#event-category').attr('data-value');
        $('#milesDropdown').removeClass('show');
        eventSearchByLocation(event_category, event_location, event_miles,0);
    }

    function eventSearchByLocation(event_category, event_location, event_miles,page) {

        /* console.log(event_category);
         console.log(event_location);
         console.log(event_miles); */
        $('#upcoming_list').empty();
        $('#past_list').empty();
        $('#today_list').empty();
        var location_lat= $('#lat').val();
        var location_lng= $('#lng').val();
        $('.dynamic-loader').css("display", "block");
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        $.post(SP_source() + 'ajax/event-search-by-location', {location: event_location, miles: event_miles, category: event_category,offset:page, lat: location_lat, lng: location_lng, csrf_token: $('[name="csrf_token"]').attr('content')})
                .done(function (responseText) {

                    $('.dynamic-loader').css("display", "none");
                    console.log(responseText.data);
                    /*  console.log(responseText.data.upevents.length); */
                    if (responseText.status == 200)
                    {
                        if (responseText.data.upevents.length > 0) {
                            var events = '';
                            for (var i = 0; i < responseText.data.upevents.length; i++) {
                                var split_date = responseText.data.upevents[i].TimeZoneStartDate.split(" ");
                                var replaced_date = split_date[0].replace(/-/g , "/");
                                var start_date = new Date(replaced_date);
                                // var start_date = new Date(responseText.data.upevents[i].start_date);
                                var cover_source = SP_source() + 'event/cover/default-cover-event.png';
                                if (responseText.data.upevents[i].timeline.cover_id>0) {
                                    cover_source = SP_source() + 'event/cover/' + responseText.data.upevents[i].timeline.cover_url[0].source;
                                }
                                events += '<div class="col-md-6 mt-20">';
                                if(responseText.data.upevents[i].timeline.slug_url!=null&&responseText.data.upevents[i].timeline.slug_url!=''){
                                events += '<a href="' + SP_source() + responseText.data.upevents[i].timeline.slug_url + '" class="thumbnail impact-thumb event-dt-hgt">';
                                }else{
                                events += '<a href="' + SP_source() + responseText.data.upevents[i].timeline.username + '" class="thumbnail impact-thumb event-dt-hgt">';
                                }
                                events += '<img src=' + cover_source + ' alt="Event" class="img-responsive event_blank_space">';
                                eventName = responseText.data.upevents[i].timeline.name;
                                if((eventName.length)>49)
                                { events += '<h3 class="event_head_align">' + eventName.substring(0,eventName.indexOf(" ",40)) + ' ...</h3>'; }
                                else { events += '<h3 class="event_head_align">' + eventName + '</h3>'; }
                                events += '<p class="event_head_align" >' + monthNames[start_date.getMonth()] + ' ' + (start_date.getDate() < 10 ? '0' : '') + start_date.getDate() + ', ' + start_date.getFullYear() + '</p>';
                                events += '</a>';
                                events += '</div>';
                            }
                            events += '<div class="clearfix"></div>';
                            if(responseText.data.upeventsCount>10){
                            events += '<ul id="pagination-upcoming" class="pagination simple-pagination"></ul>';
                            }
                            $('#upcoming_list').html(events);
                            if(responseText.data.upeventsCount>10){
                            $('#pagination-upcoming').pagination({
                                items: responseText.data.upeventsCount/10,
                                itemOnPage: 7,
                                currentPage: page+1,
                                cssStyle: '',
                                prevText: '<span aria-hidden="true">&laquo;</span>',
                                nextText: '<span aria-hidden="true">&raquo;</span>',
                                onInit: function () {
                                    // fire first page loading
                                },
                                onPageClick: function (page, evt) {
                                    // some code
                                    $('html, body').animate({ scrollTop: 0 }, 'slow');
                                    ajaxcall(page-1);
                                }
                            });
                            }
                        } else {
                            var event_category_name = $('#event-category').val();
                            if(event_category_name!='All Categories'){
                                $('#upcoming_list').html('<div class="text-center">No Events found for ' +event_category_name+ ' in this location</div>');
                            }else{
                                $('#upcoming_list').html('<div class="text-center">No Events found in this location</div>');
                            }
                        }

                        if (responseText.data.pastevents.length > 0) {
                            var events = '';
                            for (var i = 0; i < responseText.data.pastevents.length; i++) {
                                var split_date = responseText.data.pastevents[i].TimeZoneStartDate.split(" ");
                                var replaced_date = split_date[0].replace(/-/g , "/");
                                var start_date = new Date(replaced_date);
                                // var start_date = new Date(responseText.data.pastevents[i].start_date);
                                var cover_source = SP_source() + 'event/cover/default-cover-event.png';
                                if (responseText.data.pastevents[i].timeline.cover_id>0) {
                                    cover_source = SP_source() + 'event/cover/' + responseText.data.pastevents[i].timeline.cover_url[0].source;
                                }
                                events += '<div class="col-md-6 mt-20">';
                                if(responseText.data.pastevents[i].timeline.slug_url!=null&&responseText.data.pastevents[i].timeline.slug_url!=''){
                                events += '<a href="' + SP_source() + responseText.data.pastevents[i].timeline.slug_url + '/eventExpireChk" class="thumbnail impact-thumb event-dt-hgt">';
                                }else{
                                events += '<a href="' + SP_source() + responseText.data.pastevents[i].timeline.username + '/eventExpireChk" class="thumbnail impact-thumb event-dt-hgt">';
                                }
                                events += '<img src=' + cover_source + ' alt="Event" class="img-responsive event_blank_space">';
                                eventName = responseText.data.pastevents[i].timeline.name;
                                if((eventName.length)>49)
                                { events += '<h3 class="event_head_align">' + eventName.substring(0,eventName.indexOf(" ",40)) + ' ...</h3>'; }
                                else { events += '<h3 class="event_head_align">' + eventName + '</h3>'; }
                                events += '<p class="event_head_align">' + monthNames[start_date.getMonth()] + ' ' + (start_date.getDate() < 10 ? '0' : '') + start_date.getDate() + ', ' + start_date.getFullYear() + '</p>';
                                events += '</a>';
                                events += '</div>';
                            }
                            events += '<div class="clearfix"></div>';
                            if(responseText.data.pasteventsCount>10){
                            events += '<ul id="pagination-past" class="pagination simple-pagination"></ul>';
                            }
                            $('#past_list').html(events);
                            if(responseText.data.pasteventsCount>10){
                            $('#pagination-past').pagination({
                                items: responseText.data.pasteventsCount/10,
                                itemOnPage: 7,
                                currentPage: page+1,
                                cssStyle: '',
                                prevText: '<span aria-hidden="true">&laquo;</span>',
                                nextText: '<span aria-hidden="true">&raquo;</span>',
                                onInit: function () {
                                    // fire first page loading
                                },
                                onPageClick: function (page, evt) {
                                    $('html, body').animate({ scrollTop: 0 }, 'slow');
                                    ajaxcall(page-1);
                                }
                            });
                            }
                        } else {
                            var event_category_name = $('#event-category').val();
                            if(event_category_name !='All Categories'){
                            $('#past_list').html('<div class="text-center">No Events found for '+event_category_name+' in this location</div>');
                            }else{
                            $('#past_list').html('<div class="text-center">No Events found in this location</div>');
                        }
                    }

                        if (responseText.data.todayevents.length > 0) {
                            var events = '';
                            for (var i = 0; i < responseText.data.todayevents.length; i++) {
                                var split_date = responseText.data.todayevents[i].TimeZoneStartDate.split(" ");
                                var replaced_date = split_date[0].replace(/-/g , "/");
                                var start_date = new Date(replaced_date);
                                // var start_date = new Date(responseText.data.todayevents[i].start_date);
                                var cover_source = SP_source() + 'event/cover/default-cover-event.png';
                                if (responseText.data.todayevents[i].timeline.cover_id>0) {
                                    cover_source = SP_source() + 'event/cover/' + responseText.data.todayevents[i].timeline.cover_url[0].source;
                                }
                                events += '<div class="col-md-6 mt-20">';
                                if(responseText.data.todayevents[i].timeline.slug_url!=null&&responseText.data.todayevents[i].timeline.slug_url!=''){
                                events += '<a href="' + SP_source() + responseText.data.todayevents[i].timeline.slug_url + '/eventExpireChk" class="thumbnail impact-thumb event-dt-hgt">';
                                }else{
                                events += '<a href="' + SP_source() + responseText.data.todayevents[i].timeline.username + '/eventExpireChk" class="thumbnail impact-thumb event-dt-hgt">';
                                }
                                events += '<img src=' + cover_source + ' alt="Event" class="img-responsive event_blank_space">';
                                eventName = responseText.data.todayevents[i].timeline.name;
                                if((eventName.length)>49)
                                { events += '<h3 class="event_head_align">' + eventName.substring(0,eventName.indexOf(" ",40)) + ' ...</h3>'; }
                                else { events += '<h3 class="event_head_align">' + eventName + '</h3>'; }
                                events += '<p class="event_head_align">' + monthNames[start_date.getMonth()] + ' ' + (start_date.getDate() < 10 ? '0' : '') + start_date.getDate() + ', ' + start_date.getFullYear() + '</p>';
                                events += '</a>';
                                events += '</div>';
                            }
                            events += '<div class="clearfix"></div>';
                            if(responseText.data.todayeventsCount>10){
                            events += '<ul id="pagination-today" class="pagination simple-pagination"></ul>';
                            }
                            $('#today_list').html(events);
                            if(responseText.data.todayeventsCount>10){
                            $('#pagination-today').pagination({
                                items: responseText.data.todayeventsCount/10,
                                itemOnPage: 7,
                                currentPage: page+1,
                                cssStyle: '',
                                prevText: '<span aria-hidden="true">&laquo;</span>',
                                nextText: '<span aria-hidden="true">&raquo;</span>',
                                onInit: function () {
                                    // fire first page loading
                                },
                                onPageClick: function (page, evt) {
                                    $('html, body').animate({ scrollTop: 0 }, 'slow');
                                    ajaxcall(page-1);
                                }
                            });
                            }
                        } else {
                            var event_category_name = $('#event-category').val();
                            if(event_category_name!='All Categories'){
                                $('#today_list').html('<div class="text-center">No Events found for '+event_category_name+' in this location</div>');
                            }else{
                                $('#today_list').html('<div class="text-center">No Events found in this location</div>');
                            }
                        }
                    } else {
                        alert(responseText.response);
                    }
                });
    }

    function myFunction(id) {
        console.log(id);
        $('.dropdown').children('span').removeClass('show');
        $('.cat-dropdown').children('div').removeClass('show');
        if(id=='categoryDropdown'){
        if($('.search-fa').hasClass('fa-times')){
        $('.search-fa').removeClass('fa-times')
        $('.search-fa').addClass('fa-search');
        $('#categoryAllCategoriesOpt').click();
        // $('#event-category').val('');
        $('#event-category').attr('data-value','0');
        }
        }
        document.getElementById(id).classList.toggle("show");
    }

    function initMap(event,that)
    {

        console.log('map initialzed');
        var key;
        var map = new google.maps.Map(document.getElementById('location-input'), {
        });
        var input = /** @type {!HTMLInputElement} */(
                document.getElementById('location-input'));
        if (window.event)
        {
            key = window.event.keyCode;

        } else
        {
            if (event)
                key = event.which;
        }
        if (key == 13) {
            //do nothing
            return false;
            //otherwise
        } else {
            var autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.bindTo('bounds', map);
            google.maps.event.addListener(autocomplete, 'place_changed', function() {
                 var place = autocomplete.getPlace();
                 if(place!=undefined){
                 $('#lat').val(place.geometry.location.lat());
                 $('#lng').val(place.geometry.location.lng());
                }
                set_location(that);
        });

            //continue as normal (allow the key press for keys other than "enter")
            return true;
        }
    }

    function searchSettingChange(that){
        var setting_id = that.id;
        var that_selected = $('#'+that.id).find(":selected").val();
        if(that_selected!=0){
        $('#'+that.id).closest('form').find('select').each(function(){
            if(this.id!=setting_id){
                var this_selected = $('#'+this.id).find(":selected").val();
                if(this_selected==that_selected){
                    $('#'+this.id+'>option:eq(0)').attr('selected', true);
                    $('#'+that.id+'>option:eq('+that_selected+')').attr('selected', true);
                }
            }
        });
      }else{
          $('#'+that.id+'>option:eq('+that_selected+')').attr('selected', true);
      }
    }

    window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')&&!event.target.matches('#location-input')&&!event.target.matches('.input-group-addon')) {
            $('#categoryDropdown').removeClass('show');
            $('.dropdown').children('span').removeClass('show');

     }
    }
    //when redirect back from event page in all-event tab remain the same page


    $(document).ready(function(){

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
//save the latest tab; use cookies if you like 'em better:
            if($( ".stg-tab li" ).hasClass( "active" ))
            {
                //store the tab in local storage when redirect back it goes to the same tab
                localStorage.setItem('selectedTab', $(this).attr('href'));

            }
        });
//go to the latest tab, if it exists:
        var selectedTab = localStorage.getItem('selectedTab');
        var event_location =  localStorage.getItem('event_location');
        var event_miles =  localStorage.getItem('event_miles');
        var event_category =  localStorage.getItem('event_category');
        var page =  localStorage.getItem('page');
        var pagination =  localStorage.getItem('pagination');
        if (selectedTab) {
            if($( ".stg-tab li" ).hasClass("active" )) {
                $(".stg-tab li").removeClass("active");
                $("a[href=" + selectedTab + "]").parent().addClass('active');

                    $('<a data-toggle="tab" href=' + selectedTab +' aria-expanded="true"></a>').click();
                    eventSearchByLocation(event_category, event_location, event_miles,page);
                    $("a[href=" + selectedTab + "]").parent().addClass('active');
                    $('#upcoming,#past,#today').removeClass('active');
                    $(selectedTab).addClass('active');
                    setTimeout(
                        function()
                        {
                           var paginate = parseInt(page);
                            ajaxcall(paginate);

                        }, 500);




            }
        }
    });
        $(document).ready(function(){
        var username =$(this).find('.username').val();
        var all_event =SP_source()+username+'/all-events';

        if( window.location.href === SP_source())
        {
            //clear the data when home is redirected
            var selectedTab = localStorage.getItem('selectedTab');
            if(selectedTab){
                localStorage.clear();
            }

        }

    });


    $(document).ready(function(){
       var url_imp =SP_source()+'impact';
        if(window.location.href === url_imp ){
            localStorage.clear();
        }

   });

    // to empty the textarea when loaded..
    $(document).ready(function() {
        $('textarea#createPost').val('');
    });
