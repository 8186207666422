
$(function () {

    $("#btnLoginSubmit").click(function(){ $("#frm-login").submit();});

    $('.login-form').ajaxForm({
        url: SP_source() + 'login',

        beforeSend: function() {
            login_form = $('.login-form');
            login_button = login_form.find('.btn-submit');
            login_button.attr('disabled', true);
            $('.login-progress').removeClass('hidden');
            $('.login-errors').html('');
        },

        success: function(responseText) {

          login_button.attr('disabled', false);
          $('.login-progress').addClass('hidden');
            if (responseText.status == 200) {
                if (responseText.redirect_url!="")
                {
                  window.location = responseText.redirect_url;
                }
                else
                {
                  window.location = SP_source();
                }
            } else {
              $('.login').addClass('hidden');
              $('.login-errors').html(responseText.message);
                setTimeout(function() {
                    // $('#password,#email,#username').val('');
                    $('.register_input').val('');
                }, 2000);


            }

        }
    });



    $('.signup-form').ajaxForm({
        url: SP_source() + 'register',

        beforeSend: function() {
          signup_form = $('.signup-form');
          signup_button = signup_form.find('.btn-submit');
          signup_button.attr('disabled', true);
          signup_button.html('Please Wait!!....');
        },

        success: function(responseText) {
            signup_button.attr('disabled', false);
            signup_button.html('Sign up to Dashboard');
          if (responseText.status == 200) {
            $('.signupformcss').addClass('hidden');
              $('.signupformcssRemove').removeClass('hidden');
            //  window.location = SP_source();
          } else {
                $('.signup-errors').html('');
                $.each(responseText.err_result, function(key, value) {
                    $('.signup-errors').append('<li>'+ value[0] + '</li>');
                });

            }
            $("html, body").animate({ scrollTop: 0 }, "slow");

        }
    });


});
//notification count

$(document).on('click', '.notification_count', function (e) {
    var id = $(this).data('count_id');
    $.post(SP_source() + 'ajax/notification-count', {id: id}, function (data) {

    });
});

// Resend registration email
function fresendEmail(resendemail)
{
$('.login-errors').html('Please wait....');
$.post( SP_source() + 'ajax/resend-verify-email', { resendemail: resendemail, csrf_token: $('[name="csrf_token"]').attr('content') })
  .done(function( responseText ) {
    if(responseText.status == 200)
    {
        $('.login-errors').html(responseText.message);
    }
  });
}




// updated the terms and condition for existing user
jQuery(document).ready(function(){
    if( $('.user_term_value').val() == '') {
        jQuery("#termService").fancybox({
            'onStart': function () {
                $("#divForm").css("display", "block");
            },
            'onClosed': function () {
                $("#divForm").css("display", "none");
            },
            clickSlide: false,
            touch:false,

        });

        $("#termService").trigger("click");

    }
});
