function showValue(val,teamoritem,slidernum,vertical,Flag) {
  if(Flag==1)
  {
    minrange = $("#slider"+teamoritem+slidernum).data("minrange");
    if(minrange=="") minrange=0;
    minrange=parseInt(minrange);
    if(minrange>=val) val=minrange;
  }
    var thumb = document.getElementById("sliderthumb" +teamoritem+ slidernum);
    var shell = document.getElementById("slidershell" + teamoritem+slidernum);
    var track = document.getElementById("slidertrack" + teamoritem+slidernum);
    var fill = document.getElementById("sliderfill" + teamoritem+slidernum);
    var rangevalue = document.getElementById("slidervalue" +teamoritem+ slidernum);
    var slider = document.getElementById("slider" +teamoritem+ slidernum);

    var pc = val/(slider.max - slider.min); /* the percentage slider value */
    var thumbsize = 40; /* must match the thumb size in your css */
    var bigval = 350; /* widest or tallest value depending on orientation */
    var smallval = 40; /* narrowest or shortest value depending on orientation */
    var tracksize = bigval - thumbsize;
    var fillsize = 16;
    var filloffset = 10;
    var bordersize = 2;
    var loc = vertical ? (1 - pc) * tracksize : pc * tracksize;
    var degrees = 360 * pc;
    var rotation = "rotate(" + degrees + "deg)";

    rangevalue.innerHTML = val;
    $(".briningcount"+slidernum).html(val);
    if(teamoritem == 'itm')
    {
      totalcount = $("#slider"+teamoritem+slidernum).data("total-count");
      usedcount = $("#slider"+teamoritem+slidernum).data("used-count");
      indcount = $("#slider"+teamoritem+slidernum).data("individual-count");
      maxrange = (totalcount-usedcount)+indcount-parseInt(val);
      $(".morequantity"+slidernum).html(maxrange);
    }
    thumb.style.webkitTransform = rotation;
    thumb.style.MozTransform = rotation;
    thumb.style.msTransform = rotation;

    fill.style.opacity = pc + 0.2 > 1 ? 1 : pc + 0.2;

    rangevalue.style.top = (vertical ? loc : 0) + "px";
    rangevalue.style.left = (vertical ? 0 : loc) + "px";
    thumb.style.top =  (vertical ? loc : 0) + "px";
    thumb.style.left = (vertical ? 0 : loc) + "px";
    fill.style.top = (vertical ? loc + (thumbsize/2) : filloffset + bordersize) + "px";
    fill.style.left = (vertical ? filloffset + bordersize : 0) + "px";
    fill.style.width = (vertical ? fillsize : loc + (thumbsize/2)) + "px";
    fill.style.height = (vertical ? bigval - filloffset - fillsize - loc : fillsize) + "px";
    shell.style.height = (vertical ? bigval : smallval) + "px";
    shell.style.width = (vertical ? smallval : bigval) + "px";
    track.style.height = (vertical ? bigval - 4 : fillsize) + "px"; /* adjust for border */
    track.style.width = (vertical ? fillsize : bigval - 4) + "px"; /* adjust for border */
    track.style.left = (vertical ? filloffset + bordersize : 0) + "px";
    track.style.top = (vertical ? 0 : filloffset + bordersize) + "px";
}
/* we often need a function to set the slider values on page load */
function setValue(val,teamoritem,num,vertical,Flag) {
    document.getElementById("slider"+teamoritem+num).value = val;
    showValue(val,teamoritem,num,vertical,Flag);
}
 /* above script should always before the belw*/
 $(document).on('click','.accepted',function(e){
     var volteamid = $(this).data("volteamid");
     var acceptedcount = $(this).data("acceptedcount");
     var buttoncaption = $("#accepteded-"+volteamid).html();
     if(buttoncaption=="Step Out")
     {
        $("#accepted-"+volteamid).html('Count Me In');
        ///
        var uniqId=volteamid+"~"+0;
        var teamdetails = $("#eventteamdetails").val();
        var teamdetails = fPushPopStr(teamdetails,volteamid,5);
        $("#eventteamdetails").val(teamdetails);
        ///
     }
     else
     {
       $("#accepted-"+volteamid).html('Step Out');
       var acceptedcount=parseInt(acceptedcount)+1;
       ///
       var uniqId=volteamid+"~"+1;
       var teamdetails = $("#eventteamdetails").val();
       var teamdetails = fPushPopStr(teamdetails,volteamid,2);
       $("#eventteamdetails").val(teamdetails);
       ///
     }
       if (acceptedcount<0) { acceptedcount=0}
      showValue(acceptedcount,'team',volteamid,false);
 });

 $(document).on('click','.accept',function(e){
    var volteamid = $(this).data("volteamid");
    var acceptedcount = $(this).data("acceptedcount");
    var buttoncaption = $("#accepted-"+volteamid).html();
      if(buttoncaption=="Step Out")
    {
       $("#accepted-"+volteamid).html('Count Me In');
       $("#rejected-"+volteamid).css('display','none');
       ///
       var uniqId=volteamid+"~"+0;
       var teamdetails = $("#eventteamdetails").val();
       var teamdetails = fPushPopStr(teamdetails,volteamid,5);
       $("#eventteamdetails").val(teamdetails);
       ///
    }
    else if(buttoncaption=="Count Me In")
    {

      return;
    }
    else
    {
     $("#rejected-"+volteamid).css('display','none');
     $("#accepted-"+volteamid).html('Step Out');
      var acceptedcount=parseInt(acceptedcount)+1;
      ///
      var uniqId=volteamid+"~"+1;
      var teamdetails = $("#eventteamdetails").val();
      var teamdetails = fPushPopStr(teamdetails,volteamid,2);
      $("#eventteamdetails").val(teamdetails);
      ///
    }
     if (acceptedcount<0) { acceptedcount=0}
     showValue(acceptedcount,'team',volteamid,false);
 });
 $(document).on('click','.reject',function(e){
    var volteamid = $(this).data("volteamid");
    var acceptedcount = $(this).data("acceptedcount");
    if($("#accepted-"+volteamid).html()=="Step Out")
    {
       $("#accepted-"+volteamid).html('Accept');
       $("#rejected-"+volteamid).css('display','inline');
       ///
       var uniqId=volteamid+"~"+0;
       var teamdetails = $("#eventteamdetails").val();
       var teamdetails = fPushPopStr(teamdetails,volteamid,5);
       $("#eventteamdetails").val(teamdetails);
       ///
    }
    else if($("#accepted-"+volteamid).html()=="Count Me In")
    {

      return;
    }
    else
    {
     $("#rejected-"+volteamid).css('display','none');
     $("#accepted-"+volteamid).html('Count Me In');
     $("#accepted-"+volteamid).css('color','#C6C0C0');
     $("#accepted-"+volteamid).css('pointer-events','none');

     var acceptedcount=parseInt(acceptedcount)-1;
      var uniqId=volteamid+"~"+1;
      var teamdetails = $("#eventteamdetails").val();
      var teamdetails = fPushPopStr(teamdetails,volteamid,3);
      $("#eventteamdetails").val(teamdetails);
    }
     if (acceptedcount<0) { acceptedcount=0}
     showValue(acceptedcount,'team',volteamid,false);
 });

 $(document).on('click','.countmein',function(e){
   //showValue(35,2345,false);
   var volteamid = $(this).data("volteamid");
   var inorout = $("#countmein-"+volteamid).html();
   var acceptedcount = $(this).data("acceptedcount");
   var totalinvitation = $(this).data("acceptedcount");
   var acceptedcount = parseInt(acceptedcount);
   if (inorout=="Step Out")   {
     $("#countmein-"+volteamid).html('Count Me In');
     $(".adminapproval-"+volteamid).html('');
     // Remove form added list for save into database
    minrange = document.getElementById("slidervalueteam"+ volteamid).innerHTML;
     acceptedcount=parseInt(minrange)-1;
     var uniqId=volteamid+"~"+0;
     var teamdetails = $("#eventteamdetails").val();
     var teamdetails = fPushPopStr(teamdetails,volteamid,0);
     $("#eventteamdetails").val(teamdetails);
   }
   else {
     minrange = document.getElementById("slidervalueteam"+ volteamid).innerHTML;
     acceptedcount=parseInt(minrange)+1;
     $("#countmein-"+volteamid).html('Step Out');
     var uniqId=volteamid+"~"+1;
     var teamdetails = $("#eventteamdetails").val();
     var teamdetails = fPushPopStr(teamdetails,volteamid,1);
     $("#eventteamdetails").val(teamdetails);
    }
    // alert(acceptedcount+" - "+minrange);
    $("#sliderteam"+volteamid).val(acceptedcount);

   if (acceptedcount<0) { acceptedcount=0}

   // showValue(acceptedcount,'team',volteamid,false);
 });

// SourceData   -- Combination of records/string separated with | delimiter
// SearchString -- String has to be searched in the sourceData
// PushPopFlag  -- Indicated do we need to add or remove  (Add - 1 Remove - 0)
function fPushPopStr(SourceData,SearchString,PushFlag)
{
  var tmpSourceData = SourceData;
  SourceData=SourceData.split("||");
  var dataLength=SourceData.length;
  var NewSourceData = "";
  var foundFlg=0;
  if(tmpSourceData=="")
  {
     dataLength=0;
     NewSourceData=SearchString+"~"+PushFlag;
     return NewSourceData;
  }
  for(count=0;count<dataLength;count++)
  {
      var sst=SourceData[count].split("~");
          if(sst[0]==SearchString)
          {
              foundFlg = 1;
              if(NewSourceData!="") { NewSourceData = NewSourceData+"||"+sst[0]+"~"+PushFlag;	}
              if(NewSourceData=="") { NewSourceData = sst[0]+"~"+PushFlag;	}
          }
          else
          {
              if(NewSourceData!="") { NewSourceData = NewSourceData+"||"+SourceData[count];	}
              if(NewSourceData=="") { NewSourceData =SourceData[count];	}
          }
  }
    if (foundFlg==0) {
        if(tmpSourceData!="") { NewSourceData = tmpSourceData+"||"+SearchString+"~"+PushFlag;	}
        if(tmpSourceData=="") { NewSourceData = SearchString+"~"+PushFlag;	}
       }
    return NewSourceData;
}

  // Post Event Admin Changes

    $(document).on('click','.delete-event-img',function(e){
        // $('.delete-event-img').click(function(e){
            e.preventDefault();
            ide=$(this);
            id=$(this).data('id');
            $.post(SP_source() + 'ajax/remove-event-image', {id: id , csrf_token: $('[name="csrf_token"]').attr('content') }, function(data) {console.log(data);
                if (data.status == 200) {
                    if (data.delete == true) {
                        ide.parent().remove();
                        totalimage = $("#totalImageCount").val();
                        totalimage = parseInt(totalimage);
                        totalimage = totalimage-1;
                        $("#totalImageCount").val(totalimage);
                    }
                }
            });
        })
        var EVENT_POST_IMAGE_COUNT = 0;
        $(document).on('click','#removeImage',function(e){
          e.preventDefault();
          var image_holder = $("#post-image-holder");
          image_holder.empty();
          $(".selected-images").addClass("hidden");
          $(".remove-image").addClass("hidden");
          EVENT_POST_IMAGE_COUNT = 0;
        });

        $(document).on('click','#imageUploadMultiple',function(e){
          e.preventDefault();
          $('.uploaded-images').trigger('click');
        });

        $(document).on('change','.uploaded-images',function(e){
          e.preventDefault();

          var files = !!this.files ? this.files : [];

          $('.selected-images').find('span').text(files.length);
          $('.selected-images').show('slow');
          if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

          var countFiles = $(this)[0].files.length;
          $(".selected-images").removeClass("hidden");
          var imgPath = $(this)[0].value;
          var extn = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();
          var image_holder = $("#post-image-holder");
          image_holder.empty();
          if (extn == "gif" || extn == "png" || extn == "jpg" || extn == "jpeg") {
            if (typeof(FileReader) != "undefined") {
              //loop for each file selected for uploaded.
              for (var i = 0; i < countFiles; i++)
              {

                var reader = new FileReader();
                reader.onload = function(e) {
                  $("<img />", {
                    "src": e.target.result,
                    "class": "thumb-image"
                  }).appendTo(image_holder);
                }
                image_holder.show();
                reader.readAsDataURL($(this)[0].files[i]);
              }

              EVENT_POST_IMAGE_COUNT = countFiles;
              $(".image-count").html(EVENT_POST_IMAGE_COUNT);
              $(".remove-image").removeClass("hidden");
            } else {
              alert("This browser does not support FileReader.");
            }
          } else {
            alert("Pls select only images");
          }
        });
        // TODO: Need to accomdate the below javascript as well in to gulp

        $(document).on('click','#posteventadmin',function(e){
        // $("#posteventadmin").on('click',function() {
          $(".question1Error").html('');
          $(".question2Error").html('');
          $(".question3Error").html('');
          $(".question4Error").html('');
          $(".question5Error").html('');
          $(".question6Error").html('');
          $(".question7Error").html('');
          $(".question8Error").html('');
          $(".answer1").removeClass("has-error");
          $(".answer2").removeClass("has-error");
          $(".answer3").removeClass("has-error");
          $(".answer4").removeClass("has-error");
          $(".answer5").removeClass("has-error");
          $(".answer6").removeClass("has-error");
          $(".answer7").removeClass("has-error");
          $(".answer8").removeClass("has-error");
          $(".answer9").removeClass("has-error");
          $(".answer10").removeClass("has-error");

          var ErrorFlg = 0;
          var ErrorMessage = "This field is required";
          var answer1 = $("#answer1").val();
          if(answer1 == "")
          {
              $(".answer1").addClass("has-error");
              $(".question1Error").html(ErrorMessage);
              ErrorFlg = 1;
          }
          var answer2 = $("#answer2").val();
          if(answer2 == "")
          {
              $(".answer2").addClass("has-error");
              $(".question2Error").html(ErrorMessage);
              ErrorFlg = 1;
          }
          var answer3 = tinyMCE.get('answer3').getContent();
          if(answer3 == "")
          {
              $(".answer3").addClass("has-error");
              $(".question3Error").html(ErrorMessage);
              ErrorFlg = 1;
          }
          var answer4 = $("#answer4").val();
          if(answer4 == "")
          {
              $(".answer4").addClass("has-error");
              $(".question4Error").html(ErrorMessage);
              ErrorFlg=1;
          }

          var answer9 = parseInt($("#answer9").val());
          var answer10 = parseInt($("#answer10").val());
          if((answer9 > 0)||(answer10 >0 ))
          {
            var totImpact = parseInt(answer9)+parseInt(answer10);
            if(totImpact != parseInt(answer2))
            {
              $(".answer9").addClass("has-error");
              $(".answer10").addClass("has-error");
              $(".question9Error").html("Male and female count should be equal to total count");
              $(".question10Error").html("Male and female count should be equal to total count");
              ErrorFlg=1;
            }
          }
          if(ErrorFlg==1) return;
            document.forms["manage_post_event"].submit();

        });
        $(document).on('keypress',"input[name='answer1'],input[name='answer2'],input[name='answer6'],input[name='answer8'],input[name='answer9'],input[name='answer10']",function(e){
         if(!(((e.which>47)&&(e.which<58))||(e.which==8)||(e.which==127)||(e.which==0)))
         {
          return false;
         }
         if((e.which==8)||(e.which==127)) return true;
         if(this.value.length>4) {	return false;	}
        });
        $(document).on('click','.add-stories',function(e){
        				e.preventDefault();
        				ide = $(this);
        				additionalstoreis = parseInt($("#storiescount").val())+ 1;
        				var newstory = '<div class="removestories-'+additionalstoreis+'"><textarea name="additionalstories[]" rows="4" style="min-width: 100%; resize: none;"></textarea>';
        				var newstory = newstory +'<a href="javascript:void()" data-id='+additionalstoreis+' class="pull-right remove-stories"><i class="fa fa-trash-o" aria-hidden="true"></i></a><br/></div>';
        				$("#sharestories").append(newstory);
        				$("#storiescount").val(additionalstoreis);
        		});
        		$(document).on('click','.remove-stories',function(e){
        			e.preventDefault();
        			ide = $(this).data('id');
        			$('.removestories-'+ide).html('');
        		});
            $(document).ready(function(){
            	$('.charractersOnly').bind('keyup blur',function(){
            	    var node = $(this);
            	    node.val(node.val().replace(/[^a-z]/g,'') );
            		});
                $('.participate').delay(5000).slideUp(1000,'linear');
                $('input[type=checkbox][name=togBtnNewOld]').change(function() {
                      $(".Institutioncss").addClass("hidden");
                      $("#project_name").val('');
                      if(this.checked)
                      {
                          $(".projectnamecss").removeClass("hidden");
                      }
                      else {
                        $(".projectnamecss").addClass("hidden");
                      }
                    });

                    $('input[type=checkbox][name=rsvp_flag]').change(function() {
                        $('#rsvp_external_link').val('');
                          if(this.checked)
                          {
                              $(".rsvpcss").removeClass("hidden");
                          }
                          else {
                            $(".rsvpcss").addClass("hidden");
                          }
                        });
              	});

              function quickRsvp(eventId,userId) {
                    $('.RSVPYES').html('Please wait..');
                    $('.RSVPNO').addClass("hidden");
                    $.post(SP_source() + 'ajax/rsvp-flag-update', {eventId: eventId , userId: userId, csrf_token: $('[name="csrf_token"]').attr('content') }, function(data) {console.log(data);

                        if (data.status == 200) {
                            if (data.flag == 1) {
                              $("#rsvp-display").removeClass("btn-success");
                              $("#rsvp-display").addClass("btn-success");
                              $("#rsvp-display").html("Cancel RSVP");
                            }else{
                              $("#rsvp-display").removeClass("btn-default");
                              $("#rsvp-display").addClass("btn-success");
                              $("#rsvp-display").html("RSVP");
                            }
                            retdata = (data.rsvp_msg).split('#');
                            $("#rsvp_msg").html(retdata[0]);
                            if(retdata[1] !='')
                            {
                              $("#rsvp-display").attr("href", retdata[1]);
                            }
                            $(".confirmMSG").addClass('hidden');
                        }
                        $('.RSVPYES').html('Yes');
                        $('.RSVPNO').removeClass("hidden");
                    });
                }

                function fPopulateInst(oobj,errorflag,selectdval)
                  {
                    $(".institution_name").empty();
                    $(".Institutioncss").addClass("hidden");
                    if(errorflag==1){
                      $(".Institutioncss").removeClass("has-error");
                      $(".institution_name_error").html('');
                    }
                    $.post( SP_source() + 'ajax/get-service-location', { projectid:oobj , csrf_token: $('[name="csrf_token"]').attr('content') })
                      .done(function( responseText ) {
                        if(responseText.status == 200)
                        {
                          if(parseInt(responseText.servicelocationcount)>0)
                          {
                              $(".Institutioncss").removeClass("hidden");
                              serviceloc = responseText.html;
                              $('.institution_name').append('<option value="">Please select</option>');
                              $.each(serviceloc, function(key, value) {
                                    if(key==parseInt(selectdval))
                                    {
                                      $('.institution_name').append('<option value='+key+' selected>'+value+'</option>');
                                    }
                                    else {
                                      $('.institution_name').append('<option value='+key+'>'+value+'</option>');
                                    }
                              });
                            }
                        }
                      });
                  }


                  $(document).on('click','.delete-impactbanner-img',function(e) {
                      e.preventDefault();
                      ide=$(this);
                      id=$(this).data('id');
                      $.post(SP_source() + 'ajax/remove-impactbanner-image', {id: id , csrf_token: $('[name="csrf_token"]').attr('content') }, function(data) {console.log(data);
                          if (data.status == 200) {
                              if (data.delete == true) {
                                  ide.parent().remove();
                              }
                          }
                      });
                  })

                  $(document).on('click','.delete-impactthumbnail-img',function(e) {
                      e.preventDefault();
                      ide=$(this);
                      id=$(this).data('id');
                      $.post(SP_source() + 'ajax/remove-impactthumbnail-image', {id: id , csrf_token: $('[name="csrf_token"]').attr('content') }, function(data) {console.log(data);
                          if (data.status == 200) {
                              if (data.delete == true) {
                                  ide.parent().remove();
                              }
                          }
                      });
                  })

                  // Send message to event organizer

                  $(document).on('click','.contact-event-admin',function() {
                      if($('.event-admin').hasClass("hidden"))
                      {
                        $('.event-admin').removeClass("hidden");
                      }
                      else {
                        $('.event-admin').addClass("hidden");
                      }
                  });

                  $(document).on('click','#send-msg-to-event-admin',function() {
                      $('.event-admin').removeClass("has-error");
                      $('.event-contact-msg').addClass('hidden');
                      $('.event-contact-msg').html();
                      if($('#send-event-admin-msg').val()=='')
                      {
                        $('.event-admin').addClass('has-error');
                      }
                      else {      // send message
                        $('#send-msg-to-event-admin').html('Please wait..');
                        $('.event-contact-msg').delay(100).fadeIn(1000,'linear');
                        $.post( SP_source() + 'ajax/contact-event-admin', { usermsg: $('#send-event-admin-msg').val(),projectLeadId: $('#project_lead_id').val() , eventId: $('#event_id').val(), projectName: $('#project_name').val() , csrf_token: $('[name="csrf_token"]').attr('content') })
                          .done(function( responseText ) {
                              $('#send-event-admin-msg').val('');
                              $('.event-admin').addClass('hidden');
                              $('.event-contact-msg').removeClass('hidden');
                              $('.event-contact-msg').html(responseText.returnMsg);
                              $('.event-contact-msg').delay(5000).fadeOut(1000,'linear');
                              $('#send-msg-to-event-admin').html('Send Message');
                          });
                      }
                  });

                  // Send notification to email admin

                  $(document).on('click','.contact-project-admin',function() {
                      if($('.project-admin').hasClass("hidden"))
                      {
                        $('.project-admin').removeClass("hidden");
                      }
                      else {
                        $('.project-admin').addClass("hidden");
                      }
                  });
                  $(document).on('click','#send-msg-to-project-admin',function() {
                      $('.project-admin').removeClass("has-error");
                      $('.project-contact-msg').addClass('hidden');
                      $('.project-contact-msg').html();
                      if($('#send-project-admin-msg').val()=='')
                      {
                        $('.project-admin').addClass('has-error');
                      }
                      else {      // send message
                        $('#send-msg-to-project-admin').html('Please wait..');
                        $('.project-contact-msg').delay(100).fadeIn(1000,'linear');
                        $.post( SP_source() + 'ajax/contact-poject-admin', { usermsg: $('#send-project-admin-msg').val(),projectLeadId: $('#project_lead_id').val() , eventId: $('#event_id').val(), projectName: $('#project_name').val() , csrf_token: $('[name="csrf_token"]').attr('content') })
                          .done(function( responseText ) {
                              $('#send-project-admin-msg').val('');
                              $('.project-admin').addClass('hidden');
                              $('.project-contact-msg').removeClass('hidden');
                              $('.project-contact-msg').html(responseText.returnMsg);
                              $('.project-contact-msg').delay(5000).fadeOut(1000,'linear');
                              $('#send-msg-to-project-admin').html('Send Message');
                          });
                      }
                  });

                  //
                  $(document).ready(function(){

                    $(".invite-volunteer-outside").on('click',function() {
                        $('.invite-outside-people').removeClass('hidden');
                    		$('.invite-outside-people').toggle(1500);
                    });
                    $('#OutSideInvite').on('click',function() {
                    	$('.invite-outside-people').removeClass('has-error');
                    	emailto = $('#OutSideEmail').val();
                    	if(emailto=='')
                    	{
                    		$('.invite-outside-people').addClass('has-error');
                    		return;
                    	}
                      if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailto)))
                      {
                        $('.invite-outside-people').addClass('has-error');
                        return;
                      }
                    	$(".invite-outside").removeClass("hidden");

                      $('.invite-outside').delay(5000).fadeIn(300,'linear');
                        $(".invite-outside").css("display", "block");
                        $(".invite-outside").html('Please wait...');
                    	$.post( SP_source() + 'ajax/invite-outside-volunteer', { event_id: $('#txtEventId').val(), emailto: emailto, csrf_token: $('[name="csrf_token"]').attr('content') })
                    			.done(function(responseText)
                                {
                    				if(responseText.status==200)
                    				{
                                $('.invite-outside').removeClass('alert-danger');
                                $('.invite-outside').addClass('alert-success');
                                $('.invite-outside-people').toggle(1500);
                    				}
                            else {
                              $('.invite-outside').addClass('alert-danger');
                              $('.invite-outside').removeClass('alert-success');
                            }
                            $('#OutSideEmail').val('');
                            $('.invite-outside-people').addClass('hidden');
                            $('.invite-outside').html(responseText.statusString);
                            $('.invite-outside').delay(5000).fadeOut(800,'linear');
                    			});
                    });
                });

                  $(document).ready(function () {

                          //grab all header rows
                          $('th').each(function (column) {
                              $(this).addClass('location_table').click(function () {
                                  if($(this).parent().parent().parent().hasClass('table existing-products-table')){
                                    return false;
                                  }
                                  if($(this).parent().parent().parent().parent().hasClass('form_view_signed')){
                                      return false;
                                  }
                                      var findSortKey = function ($cell) {
                                          return $cell.find('.sort-key').text().toUpperCase()+ ' ' + $cell.text().toUpperCase();

                                      };
                                      var sortDirection = $(this).is('.sorted-asc') ? -1 : 1;
                                      var $rows = $(this).parent().parent().parent().find('tbody tr').get();
                                      var bob = 0;
                                      //loop through all the rows and find
                                      $.each($rows, function (index, row) {
                                          row.sortKey = findSortKey($(row).children('td').eq(column));
                                      });

                                      //compare and sort the rows alphabetically or numerically
                                      $rows.sort(function (a, b) {
                                          if (a.sortKey.indexOf('-') == -1 && (!isNaN(a.sortKey) && !isNaN(a.sortKey))) {
                                               //Rough Numeracy check

                                                  if (parseInt(a.sortKey) < parseInt(b.sortKey)) {
                                                      return -sortDirection;
                                                  }
                                                  if (parseInt(a.sortKey) > parseInt(b.sortKey)) {
                                                      return sortDirection;
                                                  }

                                          } else {
                                              if (a.sortKey < b.sortKey) {
                                                  return -sortDirection;
                                              }
                                              if (a.sortKey > b.sortKey) {
                                                  return sortDirection;
                                              }
                                          }
                                          return 0;
                                      });
                                            var itemtable=$(this).parent().parent().parent().parent();
                                            if(itemtable[0]['id'] == 'itemsTablediv'){
                                                return false;
                                            }


                                      //add the rows in the correct order to the bottom of the table
                                      $.each($rows, function (index, row) {
                                          $('tbody').append(row);
                                          row.sortKey = null;
                                      });

                                      //identify the collumn sort order
                                      $('th').removeClass('sorted-asc sorted-desc');
                                      var $sortHead = $('th').filter(':nth-child(' + (column + 1) + ')');
                                      sortDirection == 1 ? $sortHead.addClass('sorted-asc') : $sortHead.addClass('sorted-desc');

                                      //identify the collum to be sorted by
                                      $('td').removeClass('sorted').filter(':nth-child(' + (column + 1) + ')').addClass('sorted');
                                  });
                              });
                          });
// Myevents Menu on tab click
$(document).ready(function(){
    var username = $('#usernamehide').val();
    var pagination = $('#pagination').val();

    $('.organizing').on('click',function() {
        if($.isNumeric( pagination )){
            window.location = SP_source() +username+'/events';
        }

    });
    $('.participating').on('click',function() {
        if($.isNumeric( pagination )){
            window.location = SP_source() +username+'/events?tab=' + 2;
        }

    });
    $('.past').on('click',function() {
        if($.isNumeric( pagination )){
        window.location = SP_source() +username+'/events?tab=' + 3;
        }
    });
    $('.event_instance').on('click',function() {
        if($.isNumeric( pagination )){
            window.location = SP_source() +username+'/events?tab=' + 1+'&event_tab='+1;
        }
    });
    $('.event_recuring').on('click',function() {
        if($.isNumeric( pagination )){
            window.location = SP_source() +username+'/events?tab=' + 1+'&event_tab='+2;
        }
    });
});


// if recuuring is event is check

$(document).ready(function(){
    if( $('#recuring_on').val() == 1) {
        recuuring();
    }
});
// Clicks on Recurring Checkbox
$(document).on('click','.control-freq-checkbox',function(e){
    recuuring();
});


function recuuring() {
    $('.recurring_freq').css('display', 'none');
    $('#recurring_frequency').val('');
    fRecurrHideShow('.Mode-Daily', 'none');
    fRecurrHideShow('.Mode-Weekly', 'none');
    fRecurrHideShow('.Mode-Monthly', 'none');
    fRecurrHideShow('.Mode-Ends', 'none');
    fRecurrHideShow('.End-time', 'none');
    $("input[name='end_date']").attr('disabled', false);
    $("input[name='end_date']").val('');
    $("input[name='end_date']").attr('placeholder', '1970-01-01');
    $("#BtnEventTemplateCreate").css('display', 'none');
    $("#BtnEventCreate").css('display', 'inline');
    $(".create-evt-display").css('display', 'inline');
    $(".create-evtrecur-display").css('display', 'none');
    if ($('.control-freq-checkbox').prop('checked')) {
        $("input[name='end_date']").val();
        $("input[name='end_date']").attr('placeholder', '');
        $("input[name='end_date']").attr('disabled', true);
        $('.recurring_freq').css('display', 'inline');
        $("#BtnEventTemplateCreate").css('display', 'inline');
        $("#BtnEventCreate").css('display', 'none');
        $(".create-evt-display").css('display', 'none');
        $(".create-evtrecur-display").css('display', 'inline');

        return;
    }
}


$(document).on('click', '.invite-list-profile a', function (e) {
    e.preventDefault();
    var img_tag = $(this).parent().find('img').attr("src");
    var img_text = $(this).parent().find('span').text();
    var img_href = $(this).attr("href");

    var jc = $.confirm({
        title:' <a href="'+img_href+'" target="_blank">'+img_text+'</a>',
        content: '<a href="'+img_href+'" target="_blank"><img src='+img_tag+'></a>',
        animation: 'scale',
        animationClose: 'top',
        confirmButton: '',
        cancelButton: '',
        draggable: true,
        backgroundDismiss: true


    });


});

//pagination 3dot enable for myeventsand search for according to the number user want for past
$(document).ready(function(){
    var rows=$('ul.pagination li ');
    var count=1;
    $('div#past div.row div.pull-left ul.pagination li').each(function(index,val){
        var s=val.innerText;
        if(val.innerText == '...'){
            if(count >= 2) {
                $(this).children(0).remove();
            }else {
                // $(this).children(0).remove();
                $(this).children(0).addClass('pagination-hover');
                $('.pagination-hover').empty();
                $(".pagination-hover").append('<span class="pgn-in-show"> <input type="number" maxlength="4" size="4" style="width:50px;color:#e06a26" id="past-pad-val" value="" min="1" />' + '</span>' + '<span class="pag-hvr-hid">' + '...' + '</span>');
                $(this).removeClass('disabled');
                count++;
            }

        }

    });

});
//pagination 3dot enable for myeventsand search for according to the number user want for eventinstance...
$(document).ready(function(){
    var rows=$('ul.pagination li ');
    var count=1;
    $('div#eventinstance div.pull-left ul.pagination li').each(function(index,val){
        var s=val.innerText;
        if(val.innerText == '...'){
            if(count >= 2) {
                $(this).children(0).remove();
            }else {
                // $(this).children(0).remove();
                $(this).children(0).addClass('pagination-hover');
                $('.pagination-hover').empty();
                $(".pagination-hover").append('<span class="pgn-in-show"> <input type="number" maxlength="4" size="4" class="inpt_widht_sz" id="event_instance_val" value="" min="1" />' + '</span>' + '<span class="pag-hvr-hid">' + '...' + '</span>');
                $(this).removeClass('disabled');
                count++;
            }
        }
    });
});

//pagination 3dot enable for myeventsand search for according to the number user want for template...
$(document).ready(function(){
    var rows=$('ul.pagination li ');
    var count=1;
    $('div#template div.pull-left ul.pagination li').each(function(index,val){
        var s=val.innerText;
        if(val.innerText == '...'){
            if(count >= 2) {
                $(this).children(0).remove();
            }else {
                // $(this).children(0).remove();
                $(this).children(0).addClass('pagination-hover');
                $('.pagination-hover').empty();
                $(".pagination-hover").append('<span class="pgn-in-show"> <input type="number" maxlength="4" size="4" class="inpt_widht_sz" id="template_instance_val" value="" min="1" />' + '</span>' + '<span class="pag-hvr-hid">' + '...' + '</span>');
                $(this).removeClass('disabled');
                count++;
            }
        }
    });
});

$(document).ready(function(){

    $('.pagination-hover').click(function () {
        $('.pag-hvr-hid').css("display", "none");
        $('.pgn-in-show').css("display", "block");

    });

    $('#past-pad-val').keypress(function (e) {
        var key = e.which;
       var past_page= $('div#past div.row div.pull-left ul.pagination li:nth-last-child(2)');
        var past_page_count =parseInt(past_page[0].innerText);
        var pastval=$('#past-pad-val').val();
        if(key == 13)  // the enter key code
        {
            if(pastval <= past_page_count){
                window.location = SP_source() +current_username+'/events?tab=' + 3+'&page='+pastval;
            }


        }
    });
    $('#past-pad-val').blur(function (e) {
        setTimeout(
            function()
            {
        var pastval=$('#past-pad-val').val();
        if(pastval != ""){
            window.location = SP_source() +current_username+'/events?tab=' + 3+'&page='+pastval;
        }
            }, 500);
    });

    $('#event_instance_val').keypress(function (e) {
        var key = e.which;
        var pastval=$('#event_instance_val').val();
        if(key == 13)  // the enter key code
        {
            window.location = SP_source() +current_username+'/events?tab=' + 1+'&event_tab='+1+'&page='+pastval;
        }
    });
    $('#template_instance_val').keypress(function (e) {
        var key = e.which;
        var pastval=$('#template_instance_val').val();
        if(key == 13)  // the enter key code
        {
            window.location = SP_source() +current_username+'/events?tab=' + 1+'&event_tab='+2+'&page='+pastval;
        }
    });

});

// go back option for who do you need section
$(document).ready(function() {

    var bodyColor = $('#WhoYouNeedMain').css("visibility");
    var check_configure =$('#check-configure').val();
    var createEvent =$('#CreatEventseg').val();
    localStorage.setItem('cond',1);
    localStorage.removeItem('save_need');
    window.onhashchange = function ()
    {
        var bodyColor1 = $('#WhatYouNeedMain').css("display");
        var bodyColor2 = $('#WhoYouNeedMain').css("visibility");
        var what_need=localStorage.getItem('save_need');
        var cond =localStorage.getItem('cond');
        if (bodyColor1 ==='none' && cond >1)
        {
            $("#WhatYouNeedMain").css('display', 'block');
            $("#WhoYouNeedMain").css('visibility', 'hidden');
            localStorage.removeItem('cond');
            if(what_need === null && createEvent !=='create-event' ){
                window.location =window.location.href;
            }

            $(window).scrollTop($('#WhatYouNeedMain').offset().top);


        }
        var  checkcond =localStorage.getItem('cond');

        if(checkcond === null){
            localStorage.removeItem('cond');
            localStorage.setItem('cond', '1');
        }
        else
        {
            localStorage.removeItem('cond');
            localStorage.setItem('cond', '2');
        }

    }


    // }

});

$(document).on('click','.quick-login-btn',function(e){
    e.preventDefault();
    var email = $('#quick-email').val();
    var password = $('#quick-password').val();
    if(('' == email)||('' == password))
    {
        $('.login-verify-email-fail').fadeIn(1500,'linear');
        $('.login-verify-email-fail').removeClass('hidden');
        $('.login-verify-email-fail').html('Mandatory field(s) missing');
        $('.login-verify-email-fail').delay(5000).fadeOut(1500,'linear');
        return;
    }
    $.post(SP_source() + 'ajax/authenticate', {email: email , password:password }, function(data) {
        if (data.status == 200) {
            location.reload();
        }
        else {
            $('.login-verify-email-fail').fadeIn(1500,'linear');
            $('.login-verify-email-fail').removeClass('hidden');
            $('.login-verify-email-fail').html(data.message);
            $('.login-verify-email-fail').delay(5000).fadeOut(1500,'linear');
        }
    });
});


$(document).ready(function() {
    var conceptName = $('#city_id_admin').find(":selected").text();
    if(conceptName) {
        $('.city_admin_sub_fn').prop('disabled', false);
    }
    else {
        $('.city_admin_sub_fn').prop('disabled', true);
    }
    $("#country_id_admin,#city_id_admin").change(function(){
        setTimeout(
            function()
            {
                var conceptName = $('#city_id_admin').find(":selected").text();
                if(conceptName) {
                    $('.city_admin_sub_fn').prop('disabled', false);
                }
                else {
                    $('.city_admin_sub_fn').prop('disabled', true);
                }

            }, 500);
    });

    $("#user_country_role").change(function(){
        $('.country_admin_sub_fn').prop('disabled', false);
    });


});

// to hide join btn in footer

$(document).ready(function() {
    var  segment = $('#page_footer').val();
    var  check_auth = $('#auth_user_footer').val();
    if(segment == 'page'){
        if(check_auth == 1){
            $('.join_owos').hide();
        }
        else{
            $('.join_owos').show();
        }

    }

});


// hidden readmore for shortdescription
$(document).ready(function() {
    $('.readtoggle_group').removeClass('smallgroup');
    var group_readheight= $('.readtoggle_group').height();
    if(group_readheight > 226){
        $('.readtoggle_group').addClass('smallgroup');
        $('.readtoggle_group_show').removeClass('hidden');
    }else {
        // $('p').contents().filter(function() { return this.nodeType === 3; }).remove();
    }


});